import React, { useContext, useRef, useState } from "react";
import styled from "styled-components";
import SlideShow from "../../components/framework/SlideShow/slideShow";
import PageWrapper from "../../components/PageWrapper/pageWrapper";

import ParagraphText from "../../components/framework/ParagraphText/paragraphText";
import ReadMoreList from "../../components/framework/ReadMoreList/readMoreList";
import Modal from "../../components/framework/Modal/modal";
import ModalCard from "../../components/framework/Modal/modalCard";
import ModalArticle from "../../components/framework/Modal/modalArticle";
import ModalGallery from "../../components/framework/Modal/modalGallery";
//import { useRouter } from "next/router";
import { useEffect } from "react";
import { zoneType } from "../../common/types/types";
import { KiService } from "../../common/api/Kulturio/KiService";
import { useTranslation } from "react-i18next";
import HeadingContainer from "../../components/modules/HeadingContainer/headingContainer";
import PageLoader from "../../components/framework/PageLoader/pageLoader";
import { Store } from "../../components/Store/store";
import { shortLangCode } from "../../common/helpers/helpers";

const Container = styled.div`
    display: flex;
    position: relative;
    flex-direction: column;
    margin-left: var(--marginLeftSm);
    margin-right: var(--marginLeftSm);    

    @media only screen and (min-width: 1024px){
        margin-left: var(--marginLeftLg);
        margin-right: var(--marginLeftLg);
    }
`;

const Heading2 = styled.h2`
    font-weight: 700;
    color: #454F63;    
    font-size: 1.5625rem;
    line-height: 1.9375rem;
    margin: 0;
    margin-top: 1em;
`;

const SubHeading = styled.p`
    font-weight: 400;
    color: var(--colorSecondary);    
    font-size: 0.875rem;
    line-height: 1.0625rem;
    margin: 0;    
`;

const SubHeadingRow = styled.div`
display: flex;
    align-items: center;
    margin-bottom: 0.5em;
`;

const CircleSpacer = styled.div`
    --size: 4px;
    display: block;    
    width: var(--size);
    height: var(--size);
    background-color:#C38420;
    border-radius: 4px;
    margin: 0 5px;
`;

const EndMargin = styled.div`
    margin-bottom: 60px;
`;


const ArtifactPage = ({ params }) => {
    const { t, i18n } = useTranslation("common");
    const { state } = useContext(Store);

    //const Router = useRouter();
    const artifactId = { artifact_id: params.artifact_id };
    const pageUrl = new URL(`${state.constants.siteUrl}/artifact/${artifactId.artifact_id}`)
    const [stopReaderAudio, setStopReaderAudio] = useState(false);
    const [data, setData] = useState([]);
    const setDataRef = useRef(setData);
    const dataRef = useRef(data);
    const backObj = useRef({ title: "general.theme", url: "/" });
    const [errorState, setErrorState] = useState(false);
    const setErrorStateRef = useRef(setErrorState);
    const [readMoreData, setReadMoreData] = useState([]);
    const setReadMoreDataRef = useRef(setReadMoreData)
    //console.log("DATA: ",data)
    const image: string = (data.length && data[0] !== undefined) ? (data[0].content.image ? data[0].content.image.media.imageUrl : data[0].imageUrl ? data[0].imageUrl : "") : "";
    const images = (data.length && data[0] !== undefined) ? (data[0].content?.images?.length ? data[0].content.images.map((img_el)=>
        img_el.media?.imageUrl
    ) : []) : [];
    const title: string = (data.length && data[0] !== undefined) && data[0].content.name ? data[0].content.name[`locale:${shortLangCode(i18n.language)}`] : "";
    const subTitle: string = ((data.length && data[0] !== undefined) && (`locale:${shortLangCode(i18n.language)}`) in data[0].content.preface) ? data[0].content.preface[`locale:${shortLangCode(i18n.language)}`].split("\n") : "";
    const infoText: string = (data.length && data[0] !== undefined) ? data[0].content.body[`locale:${shortLangCode(i18n.language)}`] : "";

    const getAudioUrl = () => {
        let _audioUrl;
        try {
            _audioUrl = data.length > 0 && data[0] !== undefined && (shortLangCode(i18n.language) === "no" ? data[0].content.audio.norwegian.media.resources.mp3.url : data[0].content.audio.english.media.resources.mp3.url);
        }
        catch (err) {
            //console.log(err);
            _audioUrl = undefined;
        }
        finally {
            return _audioUrl;
        }
    }

    const audioUrl = getAudioUrl();

    useEffect(() => {
        //console.log("\n\nartifactId: ", artifactId, "\n\n");
        if (!artifactId.artifact_id) {
            //console.log("artifactId.artifact_id: ", artifactId.artifact_id, " not set");
            return;
        }
        if (dataRef.current.length) {
            //console.log("Artifacts data length = 0")
            return;
        }
        const artifactIdArr = artifactId.artifact_id.toString().split("_");
        const zone: zoneType = artifactIdArr[0] as zoneType;
        const theme: number = parseInt(artifactIdArr[1]);
        const _artifactId: string = artifactIdArr[2];
        backObj.current.url = `/theme/${zone}_${theme}`
        const api = new KiService();
        api.getByUuid(_artifactId)
            .then((res) => {
                //console.log("_artifactId res: ", res);
                if(res === undefined){
                    setErrorStateRef.current(true);
                }
                setDataRef.current([res]);
                if (res?.content?.sections) {
                    setReadMoreDataRef.current(res.content.sections)
                }
            })
            .catch((err)=>{console.log("ERROR: ",err)})

    }, [artifactId.artifact_id]);

    return (
        <PageWrapper
            backObj={backObj.current}
            pageTitle={title ? title + " - Tidsrom" : "Tidsrom"}
            pageUrl={pageUrl}
            description={infoText}
            image={image}
        >

            {data.length > 0 && data[0] !== undefined/*&& readMoreData.length*/ ?
                <Container>

                    <SlideShow
                        imagesList={images.length ? (image ? [image, ...images]  : images)
                            : (image ? [image] : [])}
                        clickToFullscreen={true} 
                        darkDots/>

                    <HeadingContainer soundUrl={audioUrl} stopReaderAudio={stopReaderAudio} setStopReaderAudio={setStopReaderAudio}>{title}</HeadingContainer>
                    {subTitle && <SubHeadingRow>
                        <SubHeading>{subTitle[0]}</SubHeading>
                        <CircleSpacer />
                        <SubHeading>{subTitle[1]}</SubHeading>
                    </SubHeadingRow>}
                    {/*console.log(typeof infoText)*/}
                    <ParagraphText text={infoText}
                        allTextVisible={true} />

                    {readMoreData.length > 0 &&
                        <>
                            <Heading2>{t("general.readMore")}</Heading2>
                            <ReadMoreList setStopReaderAudio={setStopReaderAudio} data={readMoreData} />
                        </>}

                        <EndMargin/>
                </Container>
                :
                <PageLoader showError={errorState} />
            }

        </PageWrapper>
    );
}

export default ArtifactPage;

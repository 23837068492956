import styled from 'styled-components';
import React from "react";

const Container = styled.div`
  
`;

const IconArticle = () => {
    return (
        <Container>
            <svg
                xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="white" width="24px" height="24px">
                <path d="M0 0h24v24H0V0z" fill="none" />
                <path d="M21 11.01L3 11v2h18zM3 16h12v2H3zM21 6H3v2.01L21 8z" />
            </svg>
        </Container>
    );
};

export default IconArticle;
import React, { useEffect, useState } from "react";
import { useRef } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { KiService } from "../../../common/api/Kulturio/KiService";
import { shortLangCode } from "../../../common/helpers/helpers";
import Modal, { modalDocumentType } from "../Modal/modal";

// ICONS
import IconArrowRight from "../../modules/IconArrowRight/iconarrowright";
import IconArticle from "../../modules/IconArticle/iconArticle";
import IconMedia from "../../modules/IconMedia/iconMedia";
import IconTurntable from "../../modules/IconTurntable/iconTurntable";


const Container = styled.ul`
    
    display: flex;
    flex-wrap: nowrap;
    flex-direction: column;

    // list overrides:
    list-style: none;
    margin-block-start: 0em;
    margin-block-end: 0em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    padding-inline-start: 0px;

    margin-top: 20px;
    margin-bottom: 60px;    
`;

const IconBackground = styled.div`
    background-color: #DD994A;
    border-radius: 5px;
    width: 50px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
`;


const ImageTextContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-left: 2.5em;
    @media only screen and (min-width: 1024px){
        margin-left: 2.5em;
    }    
`;

const ImageTitle = styled.h4`
    font-weight: 400;
    font-size: 1.125rem;
    color: #000;        
    letter-spacing: 0.36px;
    line-height: 1.6875rem;
    margin: 0;
    text-align: left;
`;

const ImageSubTitle = styled.p`
    font-weight: 400;
    font-size: 0.875rem;
    color: #000;
    margin: 0;
    line-height: 1.0625rem;
    letter-spacing: 0px;
    opacity: 0.5;
`;

const ListItem = styled.li`
    
    &:not(:last-of-type) {
        margin-bottom: 16px;        
    }    
`;


const ArrowContainer = styled.div`
    align-self: center;
    margin-left: auto;    
`;

const HorizontalLine = styled.div`
    display: block;
    width: calc(100% - 5px);
    height: 1px;
    background-color: var(--colorBgGray);    
    margin-top: 15px;   

`;


const ElementContainer = styled.button`
    border: none;
    background: none;
    padding: 0;
    width: 100%;
    display: flex;
    flex-direction: row; 
    align-items: center;
    cursor: pointer;
    
    
    &:not(:last-of-type) {
        margin-bottom: 20px;        
    }    

    &:hover ${ArrowContainer} {
            opacity: 0.5;
    }  

    &:hover ${ImageTitle} {
        text-decoration: underline;
    }   
`;

const IconTypes: React.FC<IconTypesProps> = ({ iconType }) => {
    if (iconType === "article") {
        return (
            <IconArticle />
        );
    } else if (iconType === "rotatingobject") {
        return (
            <IconTurntable />
        );
    }
    else if (iconType === "media") {
        return (
            <IconMedia />
        );
    }

    return (<div></div>)

}

interface IconTypesProps {
    iconType: string;
}



interface ReadMoreListProps {
    data: Array<any>;
    setStopReaderAudio: React.Dispatch<React.SetStateAction<boolean>>;
}

const ReadMoreList: React.FC<ReadMoreListProps> = ({ data, setStopReaderAudio }) => {
    const { t, i18n } = useTranslation("common");
    const [modalOpen, setModalOpen] = useState(false);
    const modalType: React.MutableRefObject<string> = useRef("");
    const modalDataIndex: React.MutableRefObject<number> = useRef(0);
    const [sectionData, setSectionData] = useState([]);
    const setSectionDataRef = useRef(setSectionData);
    const handleElementClick = (_modalType: modalDocumentType, idx: number) => {

        modalDataIndex.current = idx;
        modalType.current = _modalType;
        setStopReaderAudio(true); // stop playing audio
        setModalOpen(true);
        //@ts-ignore
        typeof window !== "undefined" && typeof window.gtag !== "undefined" &&
            //@ts-ignore
            window.gtag('event', `modal_${_modalType}`, {
                    'event_category': "user_interaction"                     
            });
    }

    useEffect(() => {
        //console.log("USEEFFECT READMORELIST, dataLen: ", data.length);
        //console.log(data);
        if (!data.length) return;
        const api = new KiService();

        const _fetchedData = data.map(async (el, idx) => {
            /*console.group("el");
            console.log(el);
            console.log("el[el.sectionType].referenceId: ", el[el?.sectionType][0]?.referenceId)
            console.groupEnd()*/

            // Handle emtpy elements
            if (el[el.sectionType].length === 0) {
                return [];
            }
            // Media element has all required data
            if (el.sectionType === "media") {
                return el[el.sectionType];
            }
            const res = await api.getByUuid(el[el.sectionType][0].referenceId);
            //console.log("\nres_ ", idx, ": ", res);
            return res

        })

        Promise.all(_fetchedData)
            .then((allData) => {
                //console.log("allData: ", allData);
                setSectionDataRef.current(allData);
            })

    },
        [data]);

    return (
        <>
            {modalOpen && <Modal
                opacity={modalType.current === "media" ? 0.99 : 0.8}
                setModalOpen={setModalOpen}
                data={sectionData[modalDataIndex.current]}
                documentType={modalType.current as modalDocumentType}
            >
            </Modal>}
            <Container>                

                {data.length > 0 && data.map((el, idx) =>
                    <ListItem
                        key={el.toString() + idx}>
                        <ElementContainer
                           
                            onClick={() => {/*el.sectionType !== "media" ?*/ handleElementClick(el.sectionType, idx) /*: alert("Not implemented") */ }}
                            tabIndex={0}>

                            <IconBackground>
                                <IconTypes iconType={el.sectionType} />
                            </IconBackground>


                            <ImageTextContainer>
                                <ImageTitle>{
                                    (() => {
                                        try {

                                            return el.title[`locale:${shortLangCode(i18n.language)}`]
                                        }
                                        catch (err) {
                                            console.log(err);
                                            console.log(`No title found in section: ${el.sectionType} at index: ${idx}`)
                                            return "";
                                        }
                                    })()
                                }</ImageTitle>
                                <ImageSubTitle>{t(`documentType.${el.sectionType}`)}</ImageSubTitle>
                            </ImageTextContainer>
                            <ArrowContainer><IconArrowRight /></ArrowContainer>
                        </ElementContainer>
                        <HorizontalLine />
                    </ListItem>
                )}

            </Container>
        </>
    );
}

export default ReadMoreList;